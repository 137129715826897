import React from 'react';


const Home = ()=>{
  
    return (
        <div style={{ padding: 30 }}>
             <h1>Welcome to Notes home page..</h1>
        </div>
    );
};
export default Home;