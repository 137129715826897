import React, { useState } from 'react';

export const MainContext = React.createContext(null);

export const MainProvider = props =>{

    // const [movies,setMovies] = useState([
    //     {
    //         name: 'harry potter',
    //         price: '$10',
    //         id: 123
    //     },
    //     {
    //         name: 'harry potter 2',
    //         price: '$20',
    //         id: 1234
    //     },
    //     {
    //         name: 'harry potter 3',
    //         price: '$30',
    //         id: 1235
    //     }
    // ]);
    const local_tv = localStorage.getItem("tokenjwt")
    const local_name = localStorage.getItem("name")
    const local_email = localStorage.getItem("email")

    const [user, setUser] = useState({
        token: local_tv,
        detail:{},
        name:local_name,
        email:local_email,
    });

    return(
        <MainContext.Provider value={[user, setUser]}>
            {props.children}
        </MainContext.Provider>
    );
}