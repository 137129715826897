
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import {
    BrowserRouter,
    Routes,
    Route,
    Link
  } from "react-router-dom";

import Main from '../pub/main'
import React from 'react';

function Header() {
    return (
        <React.Fragment>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <Link to="/"> */}
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Notes
                        </Typography>
                        
                    {/* </Link> */}
                    {/* <Link to="/login"> */}
                        <Button color="inherit" component={Link} to="/login">Login</Button>
                        {/* <Button color="inherit" href="/login">Login</Button> */}
                    {/* </Link> */}
                </Toolbar>
            </AppBar>
            {/* <BrowserRouter>
                <Routes>
                    <Route path="/login">
                        <Main />
                    </Route>
                    <Route path="">
                        <Main />
                    </Route>
                </Routes>
            </BrowserRouter> */}

        </React.Fragment>
    );
  }
  
  export default Header;