import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from "react-router-dom";
import {MainContext} from '../MainContext';

import {Link} from 'react-router-dom';
// import { NONAME } from 'dns';

// const pages = ['Products', 'Pricing', 'Blog'];


const ResponsiveAppBar = () => {
  // const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  // const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  
  const [user,setUser] = React.useContext(MainContext);

  const navigate = useNavigate();

  //const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };
  //const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () =>{
    localStorage.clear();

    setUser({
        token:null, 
        detail:null,
        name:null,
        email:null
    });

    window.location.href = '/Login';
    window.history.pushState('','new page','/Login');
    
  }

  

  let pages = ['About'];
  let settings = ['Profile','Meetings', 'Notes', 'Dashboard', 'Logout'];

  React.useEffect(() => {
    if(user){
      pages = ['About1', 'Login1','Dashboard1'];
      settings = ['Profile1', 'Account', 'Dashboard', 'Logout'];
    } else{
      pages = ['About2', 'Login2','Dashboard2'];
      settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
    }
  }, [""]);

  

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="p"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
              <Link to={'./'} style={{textDecoration:"none", color:"white"}}>
                LOGO
              </Link>

          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <Link to={`/${page}`} style={{textDecoration:"none",color:"white"}}>
                        {page}
                        {user && user.name ? user.name:'none'}
                      </Link>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            // href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
                <Button
                  key={page}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                  <Link to={`/${page}`} style={{textDecoration:"none",color:"white"}}>
                    {page}  
                  </Link>
                </Button>
            ))}
          </Box>
          {user && user.name ?
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">
                      {
                      setting !== 'Logout' ?
                        <Link to={`/${setting}`} style={{textDecoration:"none"}}>
                          {setting}
                        </Link>
                      :
                        <Button
                          key='logout_btn'
                          onClick={handleLogout}
                          variant="text"
                          >
                          Logout
                        </Button>
                      }
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
              {/* <Button
                  key='logout_btn'
                  onClick={handleLogout}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                  Logout
              </Button> */}
            </Box>
            :
            <Link to={`/Login`} style={{textDecoration:"none",color:"white"}}>
              Login  
            </Link>
            }
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
