import React, { useEffect } from 'react';

import {MainContext} from '../MainContext';
import {getMeetings} from '../services/meetingService';
import {addMeeting} from '../services/meetingService';

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Container from "@mui/material/Container";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import FormLabel from '@mui/material/FormLabel';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import {Link} from 'react-router-dom';    


const Meetings = ()=>{

    const [meetings, setMeetings] = React.useState();
    const [user,setUser] = React.useContext(MainContext);

    const [open, setOpen] = React.useState(false);
    
    const [name, setName] = React.useState('');
    const [datadesc, setDatadesc] = React.useState('');
    const [recurring, setRecurring] = React.useState(false);
    const [meetingdt, setMeetingdt] = React.useState(dayjs('2022-04-07'));


    useEffect(() => {
        refreshMeeting();
        
      }, []);

      const refreshMeeting = () =>{

        getMeetings().then((response)=>{
            console.log(response.data);
            if(response.data.status==401){
                localStorage.clear();
                setUser({
                    token:null, 
                    detail:null,
                    name:null,
                    email:null
                });
                alert('here')
            }
            else{
                setMeetings(response.data.res)
            }
            
        }).catch((e)=>{
            if(e.response.data.status==401){
                localStorage.clear();
                setUser({
                    token:null, 
                    detail:null,
                    name:null,
                    email:null
                });
            }
        });

      }

      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
      
      const handlePost = () => {
        let meetingdtv = meetingdt.toDate();
        addMeeting(name,datadesc,recurring,meetingdtv).then((response)=>{
            console.log(response);
            handleClose();
            refreshMeeting();
        });
        // axios.post(url, data).then(response => {
        //   // Handle the response
        //   handleClose();
        // }).catch(error => {
        //   // Handle the error
        // });
      }

      const optionsList = [
        {title:'The Godfather', name:'The Godfather', label: 'The Godfather'},
        {title:'Pulp Fiction', name:'Pulp Fiction', label: 'Pulp Fiction'}
    ];
    
    return (
        // <div container style={{ padding: 30, maxWidth: 1000 }} alignItems="center">
        <Container
            maxWidth="lg"
            
            >
            <h2>Welcome to Meetings Page...</h2>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Add Meeting
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Meeting</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Meeting Title"
                        type="text"
                        fullWidth
                        value={name}
                        onChange={event => setName(event.target.value)}
                    />

                    <TextField
                        margin="dense"
                        label="Meeting Description (Optional)"
                        type="text"
                        fullWidth
                        value={datadesc}
                        onChange={event => setDatadesc(event.target.value)}
                    />

                    <FormLabel id="demo-radio-buttons-group-label">Recurring</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="No"
                        name="radio-buttons-group"
                        onChange={event=> setRecurring(event.target.value)}
                        margin="dense"
                        label="Recurring"
                    >
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                        
                    </RadioGroup>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="DateTimePicker"
                            value={meetingdt}
                            onChange={(newValue) => {setMeetingdt(newValue)}}
                        />
                    </LocalizationProvider>
                    
                    
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handlePost} color="primary">
                    Add Meeting
                </Button>
                </DialogActions>
            </Dialog>
            
            {
                meetings && meetings.map((meeting)=>{
                    return (
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }} key={meeting._id}>
                            <Box sx={{ my: 3, mx: 2 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs>
                                        <Typography gutterBottom variant="h6" component="div" align='left'>
                                            {meeting.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="body2" component="div">
                                            {meeting.insert_dt}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Typography color="text.secondary" variant="body1" align='left'>
                                            {meeting.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography color="text.secondary" variant="body2" align='left'>
                                            {meeting.meeting_at}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography color="text.secondary" variant="body3" align='left'>
                                            {meeting.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                
                            </Box>
                            <Box sx={{ mt: 0, ml:2 }}>
                                <Stack direction="row" spacing={1}>
                                    <Chip label={meeting.project} />
                                </Stack>
                            </Box>
                            <Box sx={{ mt: 0, ml: 1, mb: 1 }}>
                                <Button>
                                    <Link to={`/Notes/${meeting._id}/${meeting.name}`} style={{textDecoration:"none"}}>
                                        View
                                    </Link>
                                </Button>
                                <Button>Edit</Button>
                                <Button>Delete</Button>
                            </Box>
                            <Divider variant="middle" />
                        </Box>
                        
                        
                    );
                })
            }
        </Container>
    );
};
export default Meetings;