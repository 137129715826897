import React, { useEffect } from 'react';

import {MainContext} from '../MainContext';
import {getNotes,addNotes,getProjects,getNote,deleteNote} from '../services/notesService';

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Container from "@mui/material/Container";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

import { useParams } from 'react-router';

//import ConfirmDialog from './ConfirmDialog';

const Notes = ()=>{

    let {meetingid,meetingname="All"} = useParams();
    const [notes, setNotes] = React.useState();
    const [user,setUser] = React.useContext(MainContext);

    const [open, setOpen] = React.useState(false);
    const [confirmopen, setConfirmopen] = React.useState(false);
    
    const [username, setUsername] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [project, setProject] = React.useState('');
    const [datadesc, setDatadesc] = React.useState('');
    const [addedit, setAddedit] = React.useState('Add');
    const [noteid, setNoteid] = React.useState('');
    const [optionsList,setOptionsList] = React.useState([]);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);

    useEffect(() => {
        refreshNotes();
        refreshProjects();
        
      }, []);

      const refreshNotes = () =>{
        getNotes(meetingid).then((response)=>{
            console.log(response.data);
            if(response.data.status==401){
                localStorage.clear();
                setUser({
                    token:null, 
                    detail:null,
                    name:null,
                    email:null
                });
                alert('here')
            }
            else{
                setNotes(response.data.res)
            }
            
        }).catch((e)=>{
            if(e.response.data.status==401){
                localStorage.clear();
                setUser({
                    token:null, 
                    detail:null,
                    name:null,
                    email:null
                });
            }
        });
    }

    const refreshProjects = () =>{
        getProjects(meetingid).then((response)=>{
            console.log(response.data);
            let optionsarr = [];
            response.data.map(v=>{
                let optionstr = {};
                optionstr.title=v
                optionstr.label=v
                optionstr.name=v
                optionsarr.push(optionstr);
            });
            
            setOptionsList(optionsarr);
        }).catch((e)=>{
            console.log(e);
        });
    }

    const handleClickOpen = () => {
        setAddedit('Add');
        setNoteid('');
        setUsername('');
        setProject('');
        setTitle('');
        setDatadesc('');
        setOpen(true);
    };

    const handleClickOpenConfirm = (noteid,title) => {
        setNoteid(noteid);
        setConfirmopen(true);
    };

    const handleNoteDelete = () => {
        console.log(noteid)
        deleteNote(noteid).then((response)=>{
            setAddedit('Delete');
            console.log(response.data);
            setOpenSnackBar(true);
            refreshNotes();
            handleConfirmClose();
            setNoteid();
        });
    }
    
    const handleClose = () => {
        setOpen(false);
    };
    const handleConfirmClose = () => {
        setConfirmopen(false);
    };
      
      
      const handlePost = (noteid) => {
        addNotes(username,project,title,datadesc,meetingid,noteid).then((response)=>{
            console.log(response);
            noteid?handleClose():handleClickOpen();
            setOpenSnackBar(true);
            refreshNotes();
        });
        // axios.post(url, data).then(response => {
        //   // Handle the response
        //   handleClose();
        // }).catch(error => {
        //   // Handle the error
        // });
      }

      const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackBar(false);
      };

      const handleEditClickOpen = (noteid) =>{
        console.log(noteid);
        setAddedit('Edit');
        setNoteid(noteid);
        getNote(noteid).then(res=>{
            console.log(res.data);
            setUsername(res.data.username);
            setProject(res.data.project);
            setTitle(res.data.title);
            setDatadesc(res.data.description);

        })
        setOpen(true);
      }

      const actionSnackBar = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );

      
    //   const optionsList = [
    //     {title:'The Godfather', name:'The Godfather', label: 'The Godfather'},
    //     {title:'Pulp Fiction', name:'Pulp Fiction', label: 'Pulp Fiction'}
    // ];
    
    return (
        // <div container style={{ padding: 30, maxWidth: 1000 }} alignItems="center">
        <Container
            maxWidth="lg"
            
            >
            <h2>Meeting: {meetingname}</h2>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Add Note
            </Button>

            <Dialog open={confirmopen} maxWidth="sm" fullWidth onClose={handleConfirmClose}>
                <DialogTitle>Confirm the action</DialogTitle>
                <Box position="absolute" top={0} right={0}>
                <IconButton onClick={handleConfirmClose}>
                    <CloseIcon/>
                </IconButton>
                </Box>
                <DialogContent>
                <Typography>Are you sure to delete?</Typography>
                </DialogContent>
                <DialogActions>
                <Button color="primary" onClick={handleConfirmClose}>
                    Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={handleNoteDelete}>
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{noteid?'Edit':'Add'} Note</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="User Name"
                        type="text"
                        fullWidth
                        value={username}
                        onChange={event => setUsername(event.target.value)}
                    />
                    {/* <Autocomplete
                        freeSolo
                        id="combo-box-demo"
                        options={optionsList}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Project" onChange={event => setProject(event.target.value)} />}
                        getOptionLabel={(option) => {
                            console.log(option);
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.title;
                          }}
                        onChange={event => setProject(event.target.value)}
                        value={project}
                    /> */}
                    <Autocomplete
                        freeSolo
                        id="combo-box-demo"
                        options={optionsList}
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Project" onChange={event => setProject(event.target.value)}/>}
                        onChange={(event,v) => setProject(v.name)}
                        value={project}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Title"
                        type="text"
                        fullWidth
                        value={title}
                        onChange={event => setTitle(event.target.value)}
                    />
                    
                    <TextField
                        margin="dense"
                        label="Data"
                        type="text"
                        fullWidth
                        value={datadesc}
                        onChange={event => setDatadesc(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={()=>handlePost(noteid)} color="primary">
                    {addedit} Note
                </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={openSnackBar}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
                action={actionSnackBar}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                    Note {addedit}ed!
                </Alert>
            </Snackbar>
            
            {
                notes && notes.map((note)=>{
                    return (
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }} key={note._id}>
                            <Box sx={{ my: 3, mx: 2 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs>
                                        <Typography gutterBottom variant="h6" component="div" align='left'>
                                            {note.username}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="body2" component="div">
                                            {note.insert_dt}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item>
                                        <Typography color="text.secondary" variant="body1" align='left'>
                                            {note.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography color="text.secondary" variant="body2" align='left'>
                                            {note.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                
                            </Box>
                            <Box sx={{ mt: 0, ml:2 }}>
                                <Stack direction="row" spacing={1}>
                                    <Chip label={note.project} />
                                </Stack>
                            </Box>
                            <Box sx={{ mt: 0, ml: 1, mb: 1 }}>
                                <Button onClick={()=>handleEditClickOpen(note._id)}>Edit</Button>
                                {/* <Button onClick={() => {
                                    ConfirmDialog('Do you really want to delete all the data?', () =>
                                    console.log('deleting all the data!')
                                    );
                                }}>Delete1</Button> */}
                                <Button onClick={()=>handleClickOpenConfirm(note._id,note.title)}>DELETE</Button>
                            </Box>

                            <Divider variant="middle" />
                        </Box>
                        
                        
                    );
                })
            }
        </Container>
    );
};
export default Notes;