import axios from "axios";

axios.defaults.timeout = 10000;

const http_secure = axios.create({
  //use localhost when running locally
  //baseURL: "http://localhost:5200/api",
  baseURL: "https://notes.dkyadavlab.com/api",
  //withCredentials: true,
  headers: {
    "Content-type": "application/json",
    Authorization : `Bearer ${localStorage.getItem("tokenjwt")}`,
    //'X-Requested-With': 'XMLHttpRequest'
   //"Content-type": "application/x-www-form-urlencoded"
  },
  //credentials: "same-origin"
});

const http_public = axios.create({
  //use localhost when running locally
  //baseURL: "http://localhost:5200/api",
  baseURL: "https://notes.dkyadavlab.com/api",
  //withCredentials: true,
  headers: {
    "Content-type": "application/json"
  },
  //credentials: "same-origin"
});

export {
  http_secure,
  http_public
};
