import {http_secure} from "../common/http";


export const getMeetings = () => {
  return http_secure.get("/meetings");
};

export const addMeeting = (name,datadesc,recurring,meetingdtv) => {
  return http_secure.post("/meetings",{
      name: name,
      description: datadesc,
      recurring: recurring,
      meeting_at: meetingdtv
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  
};



export default {
  getMeetings,
    addMeeting
};
