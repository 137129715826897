import {http_secure} from "../common/http";

// const upload = (files,formd, onUploadProgress) => {
//     let formData = new FormData();
//     console.log(files);
//     console.log(formd);
//     formData.append('bit', formd.bit);
//     formData.append('channel', formd.channel);
//     formData.append('rate', formd.rate);

//     for (let i = 0; i < files.length; i++) {
//         formData.append('audiofile', files[i])
//     }
//     //formData.append("audiofile", file);

//     return http.post("/upload", formData, {
//         responseType: 'arraybuffer',
//         headers: {
//             "Content-Type": "multipart/form-data",
//         },
//         onUploadProgress,
//     });
// };

export const getNotes = (meetingid) => {
  let urlparam = meetingid?`/${meetingid}`:'';

  return http_secure.get("/notes"+urlparam);
};

export const getNote = (noteid) => {
  let urlparam = noteid?`/${noteid}`:'';

  return http_secure.get("/note"+urlparam);
};

export const addNotes = (username,project,title,datadesc,meetingid,noteid) => {
  
  let formdata = {
    username: username,
    project: project,
    title: title,
    description: datadesc,
    meetingid:meetingid
  };

  if(noteid){
    formdata.id = noteid;
    return http_secure.patch("/notes",formdata);
  }
    
  else
    return http_secure.post("/notes",formdata);
  
};

export const deleteNote = (noteid) => {
  let formdata = {
    id: noteid
  };
  return http_secure.delete("/notes",{data:formdata});
}

export const getProjects = (meetingid) => {
  let urlparam = meetingid?`/${meetingid}`:'';
  return http_secure.get("/notes/projects"+urlparam);
}



// const downloadFile = (sid) => {
//   return http.get(`/downloadfile/${sid}`,{
//     responseType: 'arraybuffer',
//         headers: {
//             "Content-Type": "multipart/form-data",
//         },
//   });
// };

export default {
    getNotes,
    addNotes,
    getProjects,
    getNote,
    deleteNote
};
