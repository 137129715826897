import React, {useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Button from '@mui/material/Button';
// import ResponsiveAppBar from './components/responsiveheader';
import Container from '@mui/material/Container';

import Header from './components/header';

import { AppContext } from './context';

import ResponsiveAppBar from './components/Nav'

import {
    BrowserRouter,
    Link
  } from "react-router-dom";

  import {
    Route,Routes
  } from "react-router";

  import Home from './components/Home'
  import About from './components/About'
  import Dashboard from './components/Dashboard'
  import Meetings from './components/Meetings'
  import Notes from './components/Notes'
  import LoginForm from './components/login'
  import PrivateRoute from './components/PrivateRouting';
  import userauth from './services/auth'

  //export const AuthContext = React.createContext(null);
  import {MainProvider} from './MainContext';

function App() {
  	const [ users, setUsers ] = React.useState([]);
	const [token, setToken] = React.useState(null);
  

	// useEffect(() => {
	// 	console.log("Exucute useEffect");
	// 	const userdetail = userauth.getCurrentUser();
	// 	setToken(userdetail);
	// 	console.log(userdetail);
	// }, [""]);

  return (
    <MainProvider>
		<div className='App'>
			{/* <AuthContext.Provider value={[token,setToken]}> */}
				<ResponsiveAppBar/>
				<Routes>
					<Route path='/' element={<Home/>} />
					<Route path='/About' element={<About/>} />
					<Route path='/Login' element={<LoginForm/>} />
					{/* <Route path='/Dashboard' element={<Dashboard/>} /> */}
					<Route exact path='/' element={<PrivateRoute/>}>
						<Route exact path='/Dashboard' element={<Dashboard/>}/>
						<Route path='/Notes/:meetingid/:meetingname' element={<Notes/>}  />
						<Route exact path='/Notes' element={<Notes/>}/>
						<Route exact path='/Meetings' element={<Meetings/>}/>
					</Route>
				</Routes>
			{/* </AuthContext.Provider> */}
		</div>
	</MainProvider>
  );
}

export default App;
