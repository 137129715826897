import React, {useContext} from 'react';
// import {AuthContext} from '../App';

import {MainContext} from '../MainContext';

const Dashboard = ()=>{
    const [user,setUser] = useContext(MainContext);
    
    // const addToken = e =>{
    //     e.preventDefault();
    //     console.log(localStorage.getItem("tokenjwt"));
    //     setToken(prevToken => [...prevToken, localStorage.getItem("tokenjwt")]);
    // }

    return (
        <div style={{ padding: 30 }}>
            <h1>Welcome to Dashboard Page </h1>
            Token: {user && user.token ? user.token:'none'}
            <br/>
            User Detail: {user && user.name ? user.name:'none'}

             {/* <form onSubmit={addToken}>
                <button>Submit</button>
            </form> */}
            
        </div>
    );
};
export default Dashboard;