import React from 'react';


const About = ()=>{
  
    return (
        <div style={{ padding: 30 }}>
             <h1>Welcome to About Page</h1>
        </div>
    );
};
export default About;