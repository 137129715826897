import React from 'react';
import Axios from 'axios';
import {
    Checkbox,
    Grid,
    TextField,
    FormControlLabel,
    Paper,
    Button,
    FormGroup
} from '@mui/material';

import {MainContext} from '../MainContext';
import userauth from '../services/auth'

import { useNavigate } from "react-router-dom";

import http from "../common/http";

const LoginForm = ()=>{
    const navigate = useNavigate();
    const [checked, setChecked] = React.useState(true);
    const [username, setUername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const [loginStatus, setLoginStatus] = React.useState("");

    const [user, setUser] = React.useContext(MainContext);

    //const handleChange = (event: any) => {
        const handleChange = event => {
        setChecked(event.target.checked);
        localStorage.setItem("itemtest",'test');
    };

    const loginBtn = () => {
        //http.get("/getfileinfo");
        //Axios.post("http://localhost:5200/api/signin", {
        Axios.post("https://notes.dkyadavlab.com/api/signin", {
        //http.post({
            email: username,
            password: password,
        }, {
            headers: {
                'Content-Type': 'application/json'
              }
          })
          .then((response) => {
            setLoginStatus( response.data.message);
            localStorage.setItem("tokenjwt",response.data.token);

            const userdetail = userauth.getCurrentUser();
            
            localStorage.setItem("detail",userdetail.data);
            localStorage.setItem("name",userdetail.data.name);
            localStorage.setItem("email",userdetail.data.email);
            
            setUser({
                token:response.data.token, 
                detail:userdetail.data,
                name:userdetail.data.name,
                email:userdetail.data.email
            });

            //navigate('/Dashboard')
            window.location.href = '/Dashboard';
            window.history.pushState('','new page','/Dashboard');

        }).catch((e)=>{
            console.log(e);
            setLoginStatus (e.response.data.message);
        });
    };

    return (
        <div style={{ padding: 30 }}>
             <h1> {loginStatus}</h1>
            <Paper>
                <Grid
                    container
                    spacing={3}
                    // direction={'column'}
                    // justify={'center'}
                    // alignItems={'center'}
                >
                <Grid item xs={12}>
                    <TextField label="Username"
                        onChange = { (e) => {
                            setUername(e.target.value);
                            setLoginStatus('');
                        }}
                        
                    ></TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        label="Password" 
                        type={'password'}
                        onChange = { (e) => {
                            setPassword (e.target.value);
                            setLoginStatus('');
                        }}
                    ></TextField>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        //label={'Keep me logged in'}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label="Keep me logged in"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth onClick={loginBtn}> Login </Button>
                </Grid>
                </Grid>
            </Paper>
        </div>
    );
};
export default LoginForm;