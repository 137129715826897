import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {MainContext} from '../MainContext';

const PrivateRoute = () => {
    const [user,setUser] = React.useContext(MainContext);
    const auth = null; // determine if authorized, from context or however you're doing it
    console.log('user in PR:',user);
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return user.token ? <Outlet /> : <Navigate to="/Login" />;
}

export default PrivateRoute;